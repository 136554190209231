import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="Landing-header">
                <img src={logo} className="Landing-logo" alt="Fusspflege Pegasus - Anna Schuchowski - Logo"/>
                <h4 className="Text-white">Diese Webseite befindet sich momentan noch in der Entwicklung...</h4>
                <div className="Landing-header_text">
                    <h1>FUSSPFLEGE PEGASUS<br/><h2>ANNA SCHUCHOWSKI</h2></h1>
                    <h3>HAND- & FUSSPFLEGE <br/>(NACH MED. RICHTLINIEN GEPRÜFT)</h3>
                </div>
            </header>
            <div className="Landing-break">
                <h3>
                    Kennen Sie das Gefühl<br/> auf Wolken zu laufen?
                </h3>
            </div>
            <div className="Landing-info Container">
                <div className="Landing-info_list-wrapper">
                    <ul className="Landing-info_list">
                        <li><h3>TERMINVEREINBARUNG</h3></li>
                        <li>MONTAG - FREITAG:<br/> 16:00 - 20:00 UHR</li>
                        <li>SAMSTAG:<br/> 08:00 - 16:00 UHR</li>
                        <li>TEL./WHATSAPP:<br/> +49 (0) 162 574 961 3</li>
                        <li>MAIL:<br/> INFO@FUSSPFLEGE-PEGASUS.DE</li>
                    </ul>
                </div>
            </div>
            <a className="Link-Impressum" href="/impressum">Impressum</a>
        </div>
    );
}

export default App;
